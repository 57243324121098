(function () {
    'use strict';

    angular.module('common')
        .factory("allRoles", allRoles);

    allRoles.$inject = ['$http', 'CommonService'];

    function allRoles($http, CommonService) {

        var service = {
            getAllRoles: getAllRoles
        };

        function getAllRoles() {
            return CommonService.getAllRoles()
                .then(function (types) {
                    return loadRoles(types);
                });
        }

        return service;
    }

})();
